import styles from "./styles.module.scss";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "src/components/Input";
import Button from "src/components/Button";
import { AddEventPart } from "src/graphql/mutations";
import { useMutation } from "@apollo/client";
import { EventParts } from "src/graphql/queries";
import { Dispatch, SetStateAction, useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { addEventPart, addEventPartVariables } from "src/types/api";
import { useNavigate } from "react-router-dom";
import useAppStore from "src/store/utils";
import MapView, { PlacesAutoComplete } from "./Map";
import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";
import { getTimestamp } from "src/utils/GetTimeStamp";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

interface Values {
  name: string;
  location: string;
  date: string;
  time: string;
  geo_position: {
    geo_position_id: string;
    latitude: number;
    longitude: number;
  };
}

const AddPartSchema = Yup.object().shape({
  name: Yup.string().required("Part name is required"),
  location: Yup.string().required("Please input your event location"),
  date: Yup.date()
    .min(new Date().toISOString(), "You cannot choose a past date")
    .required("Date is required"),
  time: Yup.string()
    .matches(
      /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/,
      "Time must be in HH:mm format"
    )
    .required("Time is required"),
});

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function AddPart({ setOpen }: Props) {
  const { event } = useAppStore((state) => state);
  const [coords, setCoords] = useState<any>({
    latitude: 37.7749,
    longitude: -122.4194,
    place_id: "",
  });

  const navigate = useNavigate();
  const [addEventPart, { loading: addEventPartLoading }] = useMutation<
    addEventPart,
    addEventPartVariables
  >(AddEventPart, {
    onCompleted() {
      toast.success(<p className="toast">Added a New Part</p>);
      setOpen(false);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  function GetUserCoordinates() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function success(position: GeolocationPosition) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setCoords({ latitude, longitude });
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  useEffect(() => {
    GetUserCoordinates();
  }, []);

  console.log(coords);

  return (
    <div className={styles["AddPart"]}>
      <h1>Add Part</h1>
      <Formik
        initialValues={{
          name: "",
          location: "",
          date: "",
          time: "",
          geo_position: {
            geo_position_id: coords.place_id,
            latitude: coords.latitude,
            longitude: coords.longitude,
          },
        }}
        validationSchema={AddPartSchema}
        onSubmit={(values, actions) => {
          const combinedValue = getTimestamp(
            new Date(values.date),
            values.time
          );
          addEventPart({
            variables: {
              input: {
                event_id: event.id,
                time: combinedValue.toString(),
                name: values.name,
                part: values.name,
                address: values.location,
                geo_position: {
                  geo_position_id: coords.place_id,
                  latitude: coords.latitude.toString(),
                  longitude: coords.longitude.toString(),
                },
              },
            },
            refetchQueries: [
              {
                query: EventParts,
                variables: { eventId: event.id },
              },
            ],
          });
          // actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["AddPart-form"]}>
            <div className={styles["AddPart-form_container"]}>
              <Input
                type="text"
                name="name"
                label="Part Name"
                placeholder="e.g Mike and Ife's Engagement"
              />
              {/* <Input
                type="text"
                name="location"
                label="Location"
                placeholder="Enter Location"
              /> */}
              <PlacesAutoComplete setCoords={setCoords} name="location" />
              <MapView
                latitude={coords?.latitude}
                longitude={coords?.longitude}
              />
              <div
                style={{ display: "flex", flexDirection: "column", gap: "7px" }}
              >
                <label htmlFor="date">Date</label>
                <DatePicker
                  name="date"
                  placeholder="Enter Date"
                  defaultValue={new Date()}
                  valueFormat={{ dateStyle: "medium" }}
                  value={props.values.date ? new Date(props.values.date) : null}
                  onChange={(value) => props.setFieldValue("date", value)}
                />
              </div>
              {/* <Input
                type='datetime-local'
                name="date"
                label="Date"
                placeholder="Enter Date"
                min={new Date().toISOString()}
              /> */}
              <div
                style={{ display: "flex", flexDirection: "column", gap: "7px" }}
              >
                <label htmlFor="date">Time</label>
                <input
                  type="time"
                  name="time"
                  placeholder="Enter Time"
                  style={{
                    backgroundColor: "#FAFAFA",
                    border: "1px solid #f2f2f2",
                    borderRadius: "5px",
                    height: "47px",
                    padding: "0 10px",
                  }}
                  min={new Date().toISOString()}
                  value={props.values.time}
                  onChange={props.handleChange}
                />
              </div>
              {/* <Input
                type="time"
                name="time"
                label="Time"
                placeholder="Enter Time"
                min={new Date().toISOString()}
              /> */}
            </div>
            <div className={styles["AddPart-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Add Part"
                  disabled={
                    props.values.name === "" ||
                    props.values.location === "" ||
                    props.values.date === "" ||
                    !props.isValid ||
                    props.isSubmitting
                  }
                  loader={addEventPartLoading}
                >
                  Add Part
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddPart;
